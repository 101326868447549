<template>
  <div class="form-group">
    <span :class="blackOutClass">{{inputText}}</span>
    <!--
      serves as select drop down box.
      When clicked, the select options drop down under this text input
     -->
    <input
      type="text"
      id="hospital"
      class="select-hospital has-selection"
      autocomplete="off"
      v-model="inputVal"
      @click="viewOptions"
      @focus="onFocus"
      @blur="onFocusOut"
    />
    <ul class="hsptl-dropdown-menu hsptl hide" id="dropdown" ref="multi-select-drop-down-ul">
      <li>
        <div class="checkbox" id="" v-if="searchOptions.length > 0">
          <label>
            <input
              id="all_checked"
              type="checkbox"
              class="check-single"
              name="all_checked"
              value="All"
              :checked="isAllSelected"
              @change="(e) => updateSelectedOptions(e, 'All')"/>
            <i class="helper" style="margin: 3px 0 0 3px;"></i>
          </label>
        </div>&nbsp;
        <label style="padding-left: 25px;" v-if="searchOptions.length > 0">All</label>
        <label style="padding-left: 25px;" v-else>
          Sorry, no matching options
        </label>
      </li>
      <template v-for="(option, indx) in searchOptions">
        <li :key="'optn_' + indx">
          <div class="checkbox">
            <label>
              <!-- using 'id' is problematic (in general) as ids are supposed to be unique in the entire page
               and we are repeating the same ids up to 4 times (baseline + comparator 1,2 and 3)
               This causes the code to pick the wrong input box for the checkbox change event target.
               -->
              <input
                :id="'h_' + option.value + '_' + uuid"
                type="checkbox"
                class="check-single"
                :name="'h_' + option.value"
                :value="option.value"
                :checked="isSelected(option.value)"
                @change="(e) => updateSelectedOptions(e, option)"/>
              <i class="helper" style="margin: 3px 0 0 3px;"></i>
            </label>
          </div>&nbsp;
          <label :for="'h_' + option.value + '_' + uuid" style="padding-left: 25px;">{{ option.text }}</label>
        </li>
      </template>
    </ul>
    <label class="control-label" v-html="fieldLabel"></label>
    <i class="bar" style="margin-top: 5px;"></i>
  </div>
</template>

<script>

export default {
  name: 'MultiSelect',
  props: [
    'value',
    'optionLabel',
    'options', // all available hospitals to select for the given user
    'fieldLabel', 'searchOption'],
  data() {
    return {
      selectedIds: [],
      selectedValues: [],
      selectedHsptl: '',
      isAllSelected: false,
      searchOptions: [],
      inputText: '',
      blackOutClass: 'hospitalText',
      // we're using the same id vales for the selection input boxes. This random uuid helps distinguish those ids.
      // TODO avoid usind id field for checkboxes?
      uuid: crypto.randomUUID()
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.selectedHsptl;
      },
      set(val) {
        let searchOptions = [];
        if (val.trim() !== '') {
          this.inputText = '';
          this.selectedIds = [];
          this.selectedValues = [];
          searchOptions = this.options.filter(hsptl => hsptl.text.toLowerCase().indexOf(val.toLowerCase()) > -1);
        } else {
          searchOptions = this.getAllOptions();
          // uncheck 'All' checkbox
          this.isAllSelected = false;
          this.selectedIds.splice(0, this.selectedIds.length);
          this.selectedValues.splice(0, this.selectedValues.length);
        }
        this.searchOptions = searchOptions;
        this.selectedHsptl = val;
      }
    }
  },
  methods: {
    viewOptions() {
      // re-wamp options list
      if (this.$refs["multi-select-drop-down-ul"].classList.contains('hide')) {
        const unSelectedOptions = this.options.filter(s => this.selectedIds.indexOf(s.value) === -1);
        this.searchOptions = this.selectedValues.concat(unSelectedOptions);
        this.isAllSelected = (this.selectedValues.length === this.options.length) ? true : false;
        this.$refs["multi-select-drop-down-ul"].classList.remove('hide');
      }
    },
    onFocus(e) {
      this.blackOutClass = 'hospitalText modifySearch';
    },
    onFocusOut(e) {
      this.blackOutClass = 'hospitalText';
      this.selectedHsptl = '';
    },
    // This is called for each drop down select option to determine if the given option
    // should have a checkbox checked or not
    isSelected(id) {
      return this.selectedIds.indexOf(id) > -1;
    },
    hideOptionList(e) {
      const ele = e.target;
      if (
        ((ele.tagName.toLowerCase() === 'input' && ele.id !== 'hospital') ||
          ele.tagName.toLowerCase() !== 'input') &&
        (ele.closest('.hsptl-dropdown-menu') === undefined ||
          ele.closest('.hsptl-dropdown-menu') === null ||
          ele.closest('.hsptl-dropdown-menu').length === 0)
      ) {
        this.$refs["multi-select-drop-down-ul"].classList.add('hide');
      }
    },
    /**
     * Triggered when user clicks on a select option.
     * Emits 'input' event. AnySegmentSelect.hospitalSegment is the v-model that is being watched.
     * TODO Wherever this multiselect.vue is used, is using the same instance even though there are multiple select boxes displayed on the frontend.
     * They are linked to the inner state of the same instance.
     * @param e Why is this param named 'e'
     * @param obj Why is this param named 'obj'
     */
    updateSelectedOptions(e, obj) {

      const ele = e.target;
      let isChecked = true;
      if (typeof obj === 'string') {
        isChecked = (ele.checked) ? true : false;
        const hospitalOptions = [];
        this.selectedIds = [];
        if (isChecked) {
          this.searchOptions.map(o => {
            this.selectedIds.push(o.value);
            hospitalOptions.push(o);
          });
        } else {
          // selecting 1st as default option
          this.selectedIds.push(this.searchOptions[0].value);
          hospitalOptions.push(this.searchOptions[0]);
        }
        this.inputText = (isChecked)
          ? ((this.searchOptions.length < this.options.length) ? 'Multiple' : 'All')
          : this.searchOptions[0].text;
        this.selectedValues = hospitalOptions;
      } else {
        if (ele.checked) {
          this.selectedIds.push(obj.value);
          this.selectedValues.push(obj);
        } else {
          isChecked = false;
          const eleIndex = this.selectedValues.findIndex(o => o.value === obj.value);
          this.selectedIds.splice(eleIndex, 1);
          this.selectedValues.splice(eleIndex, 1);
        }

        // decide val = name/Multiple/All
        if (this.selectedValues.length > 0) {
          if (this.selectedValues.length > 1 && this.selectedValues.length < this.options.length) {
            this.inputText = 'Multiple';
          } else if (this.selectedValues.length === this.options.length) {
            this.inputText = 'All';
          } else {
            this.inputText = isChecked ? obj.text : this.selectedValues[0].text;
          }
        } else {
          this.selectedHsptl = '';
          this.inputText = '';
        }
      }
      this.isAllSelected = (this.selectedValues.length === this.searchOptions.length) ? true : false;
      this.$emit('input', this.selectedValues);
    },
    getAllOptions() {
      const searchOptions = [];
      for (const option of this.options) {
        searchOptions.push(option);
      }
      return searchOptions;
    },
    updateHospitalSelection(v) {
      if (v !== undefined && v !== null) {
        this.selectedValues = v;
        const selected = v;
        if (selected !== null && selected.length > 0) {
          // received searchOption from Props
          // update options
          this.selectedIds = selected.map((hsptl) => hsptl.value);
          this.isAllSelected = selected.length === this.searchOptions.length ? true : false;
          if (selected.length > 1) {
            this.inputText = this.options.length > selected.length ? 'Multiple' : 'All';
          } else {
            this.inputText = selected[0].text;
          }
        } else {
          this.selectedHsptl = '';
          this.inputText = '';
          this.selectedIds = [];
          this.selectedValues = [];
          this.isAllSelected = false;
        }
      } else {
        this.selectedHsptl = '';
        this.inputText = '';
        this.selectedIds = [];
        this.selectedValues = [];
        this.isAllSelected = false;
      }
    }
  },
  beforeMount() {
    this.searchOptions = this.getAllOptions();
  },
  mounted() {
    document.addEventListener('click', this.hideOptionList);
    // set initial value
    this.updateHospitalSelection(this.value);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideOptionList);
  },
  watch: {
    value(newVal, oldValue) {
      this.isAllSelected = false;
      this.selectedIds = [];
      this.selectedValues = [];
      if (newVal === null) {
        this.selectedHsptl = '';
        this.inputText = '';
        this.selectedIds = [];
        this.selectedValues = [];
        this.isAllSelected = false;
        this.searchOptions = this.getAllOptions();
      } else {
        this.updateHospitalSelection(newVal);
      }
    }
  }
};
</script>

<style>
.select-hospital {
  outline: 0;
  border-width: 0 0 2px;
  cursor: pointer;
  margin-top: 3px;
  position: relative;
  z-index: 1;
}
.form-group .select-hospital.has-selection ~ .control-label {
  color: #abf5d1;
  font-size: 0.75rem;
  top: -1rem;
}
.hide {
  visibility: hidden;
  display: none;
}
.show {
  visibility: visible;
  display: block;
}
.hsptl-dropdown-menu {
  list-style: none;
  position: absolute;
  border: 1px solid rgba(60, 60, 60, 0.26);
  overflow-x: hidden;
  width: 100%;
  max-height: 500px;
  padding: 5px;
  margin-top: 8px;
  background-color: #383f45;
  color: #eaebec;
  z-index: 380;
}
.hsptl-dropdown-menu > li {
  white-space: nowrap;
  line-height: 2rem;
}
.hsptl-dropdown-menu > li:hover{
  background-color: #55beda;
  color: #eaebec;
  white-space: normal;
}
.check-single {
  cursor: pointer;
}
.hospitalText {
  margin: 5px 0px 0px 2px;
  position: absolute;
  display: inline-block;
  color: #eaebec;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
}
.modifySearch {
  position: absolute;
  opacity: 0.4;
}
</style>
