<!--
  Template for displaying a drop down select box for either the data segments or hospitals
-->
<template>
  <div :class="[{ 'stacked-segments': stacked }]">
    <!-- if it's "non-hospital mode", we are using data segments
        This means the user has no access to any hospitals.
    -->
    <div v-if="noHospitalMode">
      <single-select
        name="datasegmentsingleselect"
        :disabled="disableControls"
        v-model="rwdSegment"
        :options="rwdOptions"
        optionLabel="text"
        :fieldLabel="rwdFieldLabel"
        :clearable="false"
        class="flex-1"
        :loading="disableControls"
      />
    </div>
    <div v-else>
      <div :class="[{ 'grid grid-cols-11': !stacked }]" class="rfs-m-6-t">
        <multi-select-checkbox-dropdown
          name="multiselect"
          :disabled="disableControls"
          v-model="hospitalSegment"
          :options="hospitalOptions"
          optionLabel="text"
          :fieldLabel="hospitalFieldLabel"
          :clearable="false"
          class="col-span-6"
          :loading="disableControls"
        />
        <p class="fs-20" :class="[{ 'text-center rfs-m-3': !stacked }]">or</p>
        <single-select
          :disabled="disabledRWD"
          v-model="rwdSegment"
          :options="rwdOptions"
          optionLabel="text"
          :fieldLabel="rwdFieldLabel"
          :clearable="false"
          class="col-span-4"
          :loading="disableControls"
        />
      </div>
    </div>
  </div>
</template>

<script>
  // this file is not using the old magic store modules and prob should be moved when we are cleaning up

  import singleSelect from '@/components/base/forms/single_select';
  import multiSelectCheckboxDropdown from '@/components/base/forms/multi_select_checkbox_dropdown';
  import { ref } from '@vue/composition-api';
  import { mapState } from 'vuex';

  export default {
    name: 'RealWorldDataSelectMagic',
    components: {
      singleSelect,
      multiSelectCheckboxDropdown
    },
    props: {
      stacked: Boolean,
      disableControls: {
        type: Boolean,
        default: false
      },

      rwdFieldLabel: {
        type: String,
        default() {
          return 'Real World Data';
        }
      },
      hospitalOptions: {
        type: Array
      },
      hospitalFieldLabel: {
        type: String,
        default() {
          return 'Your Hospitals';
        }
      },
      segmentMode: {
        type: String,
        // this default causes some timing issues now that both hospitals and rwd come from the API
        // so trying without a default for a while to see if there is an issue with just allowing the code to determine
        // everyone has rwd, but not everyone has hospital, so default to rwd
        default() {
          return 'hospital';
        }
      },
      updateStore: {
        type: Boolean,
        default: true
      },
      disabledRWD:{
        type: Boolean,
        default: false
      },
      diagnosisInsightsSelected: {
        type: Boolean,
        default: false
      }
    },
    inject: ['populationStore', 'populationWorkflowStore'],
    computed: {
      ...mapState('auth', {
        selectedHospitalList: (state) => state.selectedHospitalList
      }),
      rwdOptions() {
        return this.$store.getters['auth/userDataSegments'];
      },
      noHospitalMode() {
        if (!this.hospitalOptions) {
          return true;
        }
        return false;
      }
    },
    watch: {
      rwdSegment(v) {
        if (v) {
          this.hospitalSegment = null;

          // If we're handling this in the parent component, don't do it here too
          if (this.updateStore) {
            this.populationWorkflowStore.setDataSegment({
              mode: 'rwd',
              value: ref(v)
            });
          }

          this.$emit('input', {
            mode: 'rwd',
            value: ref(v).value
          });
        }
      },
      hospitalSegment(v) {
        if (v) {
          this.rwdSegment = null;
          /**
           * Whenever we select or un-select hospital 2nd time, is automatically get captured in
            populationWorkflowStore.dataSegment before calling populationWorkflowStore.setDataSegment()
            because 'v' was converted to ref() --
            So, for 2nd onward selections the condition under populationWorkflowStore.isCurrentDataSegmentEqual
            was getting failed which causes stopping API calls..
           * Thats the reason creating clone object of 'v' without directly considering as ref() */
          const v_cln = JSON.parse(JSON.stringify(v));

          // If we're handling this in the parent component, don't do it here too
          if (this.updateStore && v.length > 0) {
            this.populationWorkflowStore.setDataSegment({
              mode: 'hospital',
              value: ref(v_cln)
            });
            // sync with global store
            this.$store.commit('auth/setSelectedHospitalList', v);
          }

          this.$emit('input', {
            mode: 'hospital',
            value: ref(v_cln).value
          });
        }
      },
      diagnosisInsightsSelected(v){
        if(v){
            const hospitalArr = [];
            this.hospitalOptions[0].selected = true;
            hospitalArr.push(this.hospitalOptions[0]);
            this.hospitalSegment = hospitalArr;
        }
      },
      /**
       * We are synchronising this.hospitalSegment with global $store auth/selectedHospitalList.
       * We do not want to do this.
       * @param v
       * (We can do this here as actual issue is not because of this :: @Akshay)
       */
      selectedHospitalList(v) {
        if (this.updateStore && v !== undefined && v !== null && v.length > 0) {
          this.hospitalSegment = v;
        }
      }
    },
    data() {
      return {
        rwdSegment: null,
        hospitalSegment: null
      };
    },
    created() {
      if (this.noHospitalMode) {
        this.rwdSegment = this.rwdOptions.length > 0 ? this.rwdOptions[0] : {};
      } else {
        let segment = this.populationWorkflowStore.getDataSegment();

        if (segment.segment) {
          if (segment.mode == 'hospital') {
            /** segment(i.e. DataSegment) is reactive property.
             * When we select *Data Segment(You/RWD)* option under *Change Comparison Dimension*, the Hospital & RWD option
             * now available to each compare window & for that each compare window the same *segment* get shared
             * as a hospitalSegment. When we change/update hospital options of any one of the compare window then both are getting
             * updated as holded value/Object is refer to same *segment* and it is reactive.
             * So, passing it by breaking the reference/reactiveness.
             */
            const newSegment = JSON.parse(JSON.stringify(segment.segment));
            /* selected hospital List */
            let selectedHospitalList = this.$store.getters['auth/getSelectedHospitalList'];
            if (this.updateStore && selectedHospitalList !== undefined && selectedHospitalList !== null && selectedHospitalList.length > 0) {
              // assigning by breaking reference
              this.hospitalSegment = JSON.parse(JSON.stringify(selectedHospitalList));
            } else {
              this.hospitalSegment = newSegment;
            }

            // If we're handling this in the parent component, don't do it here too
            if (this.updateStore) {
              this.populationWorkflowStore.setDataSegment({
                mode: 'hospital',
                value: ref(newSegment)
              });
            }
          } else if (segment.mode == 'rwd') {
            this.rwdSegment = segment.segment;

            // If we're handling this in the parent component, don't do it here too
            if (this.updateStore) {
              this.populationWorkflowStore.setDataSegment({
                mode: 'rwd',
                value: ref(segment.segment)
              });
            }
          }
        } else {
          /* selected hospital List */
          let selectedHospitalList = this.$store.getters['auth/getSelectedHospitalList'];
          if (selectedHospitalList !== undefined && selectedHospitalList !== null && selectedHospitalList.length > 0) {
            this.hospitalSegment = selectedHospitalList;
          } else {
            const hospitalArr = [];
            this.hospitalOptions[0].selected = true;
            hospitalArr.push(this.hospitalOptions[0]);
            this.hospitalSegment = hospitalArr;
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .stacked-segments::before {
    content: '{';
    position: absolute;
    font-size: 80px;
    font-weight: lighter;
    margin-left: -35px;
    margin-top: -10px;
    font-family: 'Times New Roman', serif;
    opacity: 0.075;
    transform: scale(1, 2);
  }
</style>